<template>
  <a-modal
    :visible="showModal"
    title="Model Results"
    centered
    :body-style="{ height: '70vh' }"
    width="60%"
    :mask-closable="false"
    :footer="null"
    @cancel="handleCancelModal"
  >
    <div
      v-if="isLoading"
      class="d-flex flex-column justify-content-center align-item-center h-100 w-100"
    >
      <a-typography-title :level="3" style="margin: auto; color: grey">
        <div class="d-flex justify-content-center align-item-center">
          <file-done-outlined style="font-size: 35px; color: grey" />
        </div>
        <a-space>
          <span>Please wait while we fetch the results...</span>
          <loading-outlined style="font-size: 24px" />
        </a-space>
      </a-typography-title>
    </div>
    <div v-else class="w-100 h-100">
      <div class="d-flex justify-content-between" style="height: 10%">
        <a-typography-title :level="3">{{ imageHeader }}</a-typography-title>
        <a-button
          type="primary"
          :disabled="!downloadUrl"
          :loading="isDownloading"
          @click="handleDownloadFile"
        >
          <template #icon><download-outlined /></template>
          Download
        </a-button>
      </div>
      <a-carousel
        arrows
        class="carousel-slick mt-3"
        style="height: 80%"
        :beforeChange="handleBeforeChange"
        :afterChange="handleAfterChange"
      >
        <template #prevArrow>
          <div class="custom-slick-arrow" style="left: 1%; top: 50%">
            <left-circle-outlined />
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-slick-arrow" style="right: 1%; top: 50%">
            <right-circle-outlined />
          </div>
        </template>
        <template v-for="item in filesUrl">
          <a-table
            v-if="item === 'results.csv'"
            class="csv-table"
            :data-source="list"
            :columns="columns"
            :pagination="false"
            bordered
            :scroll="{
              y: '40vh',
              x: true,
            }"
          />
          <template v-else>
            <a-typography-title
              v-if="!isImageExist || preSignedUrl[item] === null"
              :level="3"
              style="margin: auto"
              ><span style="color: grey">
                No image exist.</span
              ></a-typography-title
            >
            <img
              v-else
              :src="preSignedUrl[item]"
              @error="handleImageError(item)"
            />
          </template>
        </template>
      </a-carousel>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
  FileDoneOutlined,
} from '@ant-design/icons-vue';
import S3Service from 'src/services/s3';
import axios from 'axios';
import { parse } from 'csv';
import { downloadFileUsingUrl } from 'src/components/shared/Helpers/downLoadFIleUsingUrl';
export default {
  props: ['showModal', 'record'],
  emits: ['setShowModal'],
  inject: ['toast'],
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
    DownloadOutlined,
    LoadingOutlined,
    FileDoneOutlined,
  },

  data() {
    return {
      imageHeader: null,
      isLoading: false,
      downloadUrl: null,
      image: null,
      files: [
        'confusion_matrix_normalized.png',
        'P_curve.png',
        'results.png',
        'results.csv',
      ],
      columns: [],
      list: [],
      imageListSrc: [],
      filesUrl: [],
      isImageExist: true,
      imageLoading: false,
      isDownloading: false,
    };
  },

  computed: {
    ...mapGetters([
      'modelTypes',
      'selectedTask',
      'organization',
      'taskName',
      'modelVersionsList',
      'showUploadModelButton',
      'isUpdatingModelVersion',
      'taskAssociatedModelVersion',
    ]),
  },

  watch: {
    async showModal(value) {
      if (value) await this.getData();
    },
  },

  methods: {
    handleImageError(file) {
      this.isImageExist = false;
      this.preSignedUrl[file] = null;
      this.downloadUrl = null;
    },

    async handleDownloadFile() {
      if (!this.downloadUrl) return;
      this.isDownloading = true;
      downloadFileUsingUrl(this.downloadUrl);
      this.isDownloading = false;
    },

    resetValues() {
      this.preSignedUrl = {};
      this.list = [];
      this.columns = [];
      this.filesUrl = [];
      this.isDownloading = false;
      this.downloadUrl = null;
      this.isImageExist = true;
    },

    async getData() {
      this.isLoading = true;
      this.resetValues();
      const bucket = 'retrocausal-model-versions';
      const files = this.files.map((file) => {
        const object_path = `${this.record.orgName}-training/${this.record.model_s3_key}results/${file}`;
        return { object_path: object_path, file_name: file };
      });

      const [error, data] = await S3Service.verifyGetPresignedUrl(
        bucket,
        files
      );

      if (error) {
        this.isLoading = false;
        this.$emit('setShowModal', false);
        return this.toast.error('Failed to fetch model result files.');
      }

      for (const [key, value] of Object.entries(data)) {
        this.preSignedUrl[key] = value;
        this.filesUrl.push(key);

        if (key.toLowerCase().endsWith('.csv')) {
          await this.getCSVContent(value);
        }
      }
      if (!this.filesUrl.length) {
        this.isLoading = false;
        this.$emit('setShowModal', false);
        return this.toast.info('No model result files exist!');
      }
      const file = this.filesUrl[0];
      this.imageHeader = file?.toLowerCase();
      this.downloadUrl = this.preSignedUrl[file];
      this.isLoading = false;
    },

    async getCSVContent(url) {
      try {
        const response = await axios.get(url);
        if (response.status !== 200) return;
        parse(
          response.data,
          {
            columns: true,
            skip_empty_lines: true,
          },
          (err, records) => {
            if (err) {
              console.error('Error parsing CSV:', err);
              return;
            }
            const columns = Object.keys(records[0]).map((key) => ({
              title: key,
              dataIndex: key,
              key: key,
            }));

            this.list = records;
            this.columns = columns;
          }
        );
      } catch (error) {
        this.toast.error('Failed to fetch CSV file.');
      }
    },

    handleBeforeChange(prev, current) {
      const file = this.filesUrl[current];
      this.isDownloading = false;
      if (this.preSignedUrl[file] === null) this.isImageExist = false;
      else this.isImageExist = true;
    },

    handleAfterChange(currentIndex) {
      const file = this.filesUrl[currentIndex];
      this.imageHeader = file?.toLowerCase();
      this.downloadUrl = this.preSignedUrl[file];
      if (file.toLowerCase().endsWith('.csv') && this.list.length === 0) {
        this.preSignedUrl[file] = null;
        this.downloadUrl = null;
      }
    },

    handleCancelModal() {
      this.resetValues();
      this.$emit('setShowModal', false);
    },
  },
};
</script>

<style scoped>
.csv-table {
  width: 90% !important;
}

:deep(.carousel-slick .slick-slider) {
  height: 100% !important;
}

:deep(.slick-slide img) {
  margin: auto;
  max-width: 90%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}
:deep(.slick-slide > div) {
  height: 100%;
}

:deep(.slick-list) {
  height: 100% !important;
}
:deep(.slick-slide) {
  text-align: center;
  height: 100% !important;
  line-height: 160px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.slick-track) {
  height: 100% !important;
}

:deep(.slick-arrow.custom-slick-arrow) {
  width: 3%;
  height: 4%;
  font-size: 2rem;
  color: #1890ff;
  transition: ease all 0.3s;
  opacity: 1;
  z-index: 1;
}
:deep(.slick-arrow.custom-slick-arrow:before) {
  display: none;
}
:deep(.slick-arrow.custom-slick-arrow:hover) {
  color: #40a9ff;
  opacity: 1;
}
</style>
